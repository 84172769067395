// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MITs
import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'lfx-toggle',
  templateUrl: './toggle.component.html',
  styleUrls: ['./toggle.component.scss'],
})
export class ToggleComponent {
  @Input() checked = false;
  @Input() label = '';
  @Input() showInfoIcon = false;
  @Output() toggleChange = new EventEmitter<boolean>();
  @Output() infoClick = new EventEmitter<void>();

  onCheckboxChange(event: boolean) {
    this.toggleChange.emit(event);
  }

  onInfoClick() {
    this.infoClick.emit();
  }
}
