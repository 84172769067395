<!---- Copyright The Linux Foundation and each contributor to LFX.-->
<!---- SPDX-License-Identifier: MITs -->
<div class="toggle-container">
  <span *ngIf="label">
    {{ label }}
    <i
      *ngIf="showInfoIcon"
      class="fal fa-info-circle cursor-pointer"
      (click)="onInfoClick()"
    >
    </i>
  </span>
  <div class="toggle">
    <input
      type="checkbox"
      (ngModelChange)="onCheckboxChange($event)"
      [ngModel]="checked"
    />
    <label class="m-0"></label>
  </div>
</div>
