<!---- Copyright The Linux Foundation and each contributor to LFX.-->
<!---- SPDX-License-Identifier: MIT -->
<div class="offcanvas-container">
  <div class="close-button-box cursor-pointer" (click)="onCloseModal()">
    <i class="fal fa-times"></i>
  </div>
  <div class="main-content-box">
    <div>
      <lfx-employee-info-card
        [lfUserId]="employeeId"
        [memberId]="employeeId"
        [organization]="organization"
        [otherEmails]="employee?.otherEmailAddress || []"
        [defaultEmployeeObject]="defaultEmployeeObject"
        [hasUsername]="employeeHasUsername"
        [hasDashboardAccess]="employeeHasDashboardAccess"
      ></lfx-employee-info-card>
    </div>
    <!-- Activity -->
    <div>
      <ngb-accordion #acc="ngbAccordion" [activeIds]="activeIds">
        <ngb-panel id="activity-ngb-panel">
          <ng-template ngbPanelTitle>
            <div class="accordion-title">Activity</div>
          </ng-template>
          <ng-template ngbPanelContent>
            <lfx-employee-activity-card
              [userId]="employeeId"
              [defaultFilter]="options?.activity?.defaultFilter"
              [projectId]="projectId"
            ></lfx-employee-activity-card>
          </ng-template>
        </ngb-panel>
      </ngb-accordion>
    </div>
    <!-- Access -->
    <div *ngIf="employee">
      <ngb-accordion #acc="ngbAccordion" [activeIds]="activeIds">
        <ngb-panel id="access-ngb-panel">
          <ng-template ngbPanelTitle>
            <div class="accordion-title">Access</div>
          </ng-template>
          <ng-template ngbPanelContent>
            <div
              class="d-flex justify-content-between align-items-center"
              *ngIf="employee | dashboardAccess as access"
            >
              <div class="d-flex align-items-center">
                <div class="access-title">Current Access</div>
                <div class="lf-pills-container">
                  <span
                    [class.lf-pill-dark]="access === dashboardAccess.Admin"
                    [class.lf-pill]="access !== dashboardAccess.Default"
                    >{{ access }}</span
                  >
                </div>
              </div>
              <ng-container *ngIf="canMakeUpdates$ | async">
                <div
                  class="lf-link font-14"
                  *ngIf="
                    access === dashboardAccess.Admin ||
                    access === dashboardAccess.Viewer
                  "
                  (click)="openEditAccess()"
                >
                  <i class="fal fa-pen"></i> Edit
                </div>
              </ng-container>
            </div>
          </ng-template>
        </ngb-panel>
      </ngb-accordion>
    </div>
    <!-- Key Contacts -->
    <div *ngIf="employee && keyContactsData.length">
      <ngb-accordion #acc="ngbAccordion" [activeIds]="activeIds">
        <ngb-panel id="key-contacts-ngb-panel">
          <ng-template ngbPanelTitle>
            <div class="accordion-title">
              Key Contacts ({{ keyContactsData.length }})
            </div>
          </ng-template>
          <ng-template ngbPanelContent>
            <div class="d-flex" *ngIf="canMakeUpdates$ | async">
              <div
                class="lf-link font-14 margin-left-auto"
                (click)="openEditKeyContacts()"
              >
                <i class="fal fa-pen"></i> Edit
              </div>
            </div>
            <lfx-new-table
              [columns]="keyContactsColumns"
              [data]="keyContactsData"
              [version]="2"
            ></lfx-new-table>
          </ng-template>
        </ngb-panel>
      </ngb-accordion>
    </div>
    <!-- Committees -->
    <div *ngIf="employee && committeesData.length">
      <ngb-accordion #acc="ngbAccordion" [activeIds]="activeIds">
        <ngb-panel id="committees-ngb-panel">
          <ng-template ngbPanelTitle>
            <div class="accordion-title">
              Committees ({{ committeesData.length }})
            </div>
          </ng-template>
          <ng-template ngbPanelContent>
            <div class="d-flex" *ngIf="canMakeUpdates$ | async">
              <div
                class="lf-link font-14 margin-left-auto"
                (click)="openEditCommittees()"
              >
                <i class="fal fa-pen"></i> Edit
              </div>
            </div>
            <lfx-new-table
              [columns]="committeesColumns"
              [data]="committeesData"
              [version]="2"
            ></lfx-new-table>
          </ng-template>
        </ngb-panel>
      </ngb-accordion>
    </div>
    <!-- Contributor -->
    <div *ngIf="allContributions.length">
      <ngb-accordion #acc="ngbAccordion" [activeIds]="activeIds">
        <ngb-panel id="contributor-ngb-panel">
          <ng-template ngbPanelTitle>
            <div class="accordion-title">
              Contributor ({{ allContributions.length }})
            </div>
          </ng-template>
          <ng-template ngbPanelContent>
            <lfx-new-table
              [columns]="contributorColumns"
              [data]="contributorData"
              [version]="2"
            ></lfx-new-table>
          </ng-template>
        </ngb-panel>
      </ngb-accordion>
    </div>

    <lfx-section-footer></lfx-section-footer>
  </div>
</div>

<ngx-spinner></ngx-spinner>

<ng-template #committeeProject let-row="row">
  <div class="committee-name-section">
    <div class="committee-name" [title]="row.project">
      {{ row.project }}
    </div>
    <div class="lf-pills-container">
      <span
        class="lf-pill"
        placement="bottom"
        [ngbPopover]="row.committeeName.length > 27 ? row.committeeName : ''"
        triggers="mouseenter:mouseleave"
      >
        {{ row.committeeName | ellipsis: 27 }}
      </span>
    </div>
  </div>
</ng-template>

<ng-template #contributorRole let-row="row">
  <div class="contact-type-pill">
    {{ row.role }}
  </div>
</ng-template>

<ng-template #contributorProjects let-row="row">
  <ng-container *ngFor="let project of row.projects">
    <div class="project">{{ project.parentProjectName }}</div>
    <ul>
      <li *ngFor="let subproject of project.subProjects">
        {{ subproject.projectName }}
      </li>
    </ul>
  </ng-container>
</ng-template>
