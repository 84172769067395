<!---- Copyright The Linux Foundation and each contributor to LFX.-->
<!---- SPDX-License-Identifier: MITs -->
<div
  class="search-by-project-btn"
  [ngbPopover]="filterByProjectPopContent"
  #filterByProjectPopover="ngbPopover"
  [autoClose]="'outside'"
  placement="bottom-left"
  popoverClass="lfhome-filter-by-project-menu-popover"
  (click)="openSearchProjectsPopover(filterByProjectPopover)"
  (hidden)="onSearchChange('')"
>
  <div
    class="search-by-project-btn-text heading-2"
    [title]="selectedProject?.name.text || 'Select'"
  >
    {{ selectedProject?.name.text || 'All Projects' }}
  </div>
  <i class="far fa-angle-down"></i>
</div>

<ngx-spinner
  type="ball-clip-rotate"
  [fullScreen]="false"
  name="projects-dropdown-loading"
  color="rgba(0,0,0,0.5)"
  bdColor="rgba(255,255,255,0.5)"
  size="default"
></ngx-spinner>

<ng-template #filterByProjectPopContent>
  <ng-container *ngIf="errorMessage; else tableTemplate">
    <span class="text-center text-muted">{{ errorMessage }}</span>
  </ng-container>
  <ng-template #tableTemplate>
    <table class="stripped-table">
      <thead>
        <tr>
          <th>
            <div class="search-area">
              <div class="search-projects-input-container">
                <input
                  type="text"
                  placeholder="Lookup name..."
                  (input)="onSearchChange($event.target.value)"
                />
              </div>
            </div>
          </th>
          <th>Membership Status</th>
          <th># of Commits</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngIf="!filteredProjects.length">
          <td>Project Not Found</td>
          <td></td>
          <td></td>
        </tr>
        <tr
          *ngIf="filteredProjects.length"
          class="row-border-bottom"
          [class.selected]="allFoundationsSelected"
          (click)="getAllFoundationsStats()"
        >
          <td>All Projects</td>
          <td></td>
          <td>{{ totalCommits | shortNumber }}</td>
        </tr>
        <ng-container *ngFor="let e of filteredProjects">
          <tr
            [class.selected]="e.anchorTag === selectedProject?.anchorTag"
            (click)="onRowClick(e)"
          >
            <td>
              <i
                class="fas fa-caret-down caret-style"
                *ngIf="e.expanded && e.children.length"
                (click)="toggleExpandProject(e, $event)"
              ></i>
              <i
                class="fas fa-caret-right caret-style"
                *ngIf="!e.expanded && e.children.length"
                (click)="toggleExpandProject(e, $event)"
              ></i>
              {{ e.name.text }}
            </td>
            <td>
              <div class="bullet-container">
                <div
                  class="bullet"
                  [class.green]="
                    e.membershipStatus.text.toLowerCase() === 'active'
                  "
                  [class.red]="e.membershipStatus.text.toLowerCase() === 'none'"
                  [class.orange]="
                    e.membershipStatus.text.toLowerCase() === 'expiring soon'
                  "
                ></div>
                {{ e.membershipStatus.text }}
              </div>
            </td>
            <td>
              {{ toNubmer(e.noOfCommits.text) | shortNumber }}
            </td>
          </tr>
          <ng-container *ngIf="e.expanded">
            <tr
              *ngFor="
                let c of e.children
                  | filterArrayOfObjects: childrenFilterValue:'projectName':true
              "
              (click)="onRowClick(c)"
              [class.selected]="c.anchorTag === selectedProject?.anchorTag"
              class="child"
            >
              <td>
                {{ c.name.text }}
              </td>
              <td>
                <div class="bullet-container">
                  <div
                    class="bullet"
                    [class.green]="
                      c.membershipStatus.text.toLowerCase() === 'active'
                    "
                    [class.red]="
                      c.membershipStatus.text.toLowerCase() === 'none'
                    "
                    [class.orange]="
                      c.membershipStatus.text.toLowerCase() === 'expiring soon'
                    "
                  ></div>
                  {{ e.membershipStatus.text }}
                </div>
              </td>
              <td>
                {{ toNubmer(c.noOfCommits.text) | shortNumber }}
              </td>
            </tr>
          </ng-container>
        </ng-container>
      </tbody>
    </table>
  </ng-template>
</ng-template>
