<!---- Copyright The Linux Foundation and each contributor to LFX.-->
<!---- SPDX-License-Identifier: MITs -->
<div
  class="search-by-project-btn"
  [ngbPopover]="filterByProjectPopContent"
  #filterByProjectPopover="ngbPopover"
  [autoClose]="'outside'"
  placement="bottom-left"
  popoverClass="events-filter-by-project-menu-popover"
  (click)="openSearchProjectsPopover(filterByProjectPopover)"
  (hidden)="onSearchChange('')"
>
  <div
    class="search-by-project-btn-text heading-2"
    [title]="selectedProject?.foundationName || 'Select'"
  >
    {{ selectedProject?.foundationName || 'All Projects' }}
  </div>
  <i class="far fa-angle-down"></i>
</div>

<ngx-spinner
  type="ball-clip-rotate"
  [fullScreen]="false"
  name="projects-dropdown-loading"
  color="rgba(0,0,0,0.5)"
  bdColor="rgba(255,255,255,0.5)"
  size="default"
></ngx-spinner>

<ng-template #filterByProjectPopContent>
  <ng-container *ngIf="errorMessage; else tableTemplate">
    <span class="text-center text-muted">{{ errorMessage }}</span>
  </ng-container>
  <ng-template #tableTemplate>
    <table class="stripped-table">
      <thead>
        <tr>
          <th>
            <div class="search-area">
              <div class="search-projects-input-container">
                <input
                  type="text"
                  placeholder="Lookup name..."
                  (input)="onSearchChange($event.target.value)"
                />
              </div>
            </div>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr *ngIf="!filteredProjects.length">
          <td>Project Not Found</td>
        </tr>
        <tr
          *ngIf="filteredProjects.length"
          class="row-border-bottom"
          [class.selected]="allFoundationsSelected"
          (click)="getAllFoundationsStats()"
        >
          <td>All Projects</td>
        </tr>
        <ng-container *ngFor="let e of filteredProjects">
          <tr
            [class.selected]="e.foundationId === selectedProject?.foundationId"
            (click)="onRowClick(e)"
          >
            <td>
              {{ e.foundationName }}
            </td>
          </tr>
        </ng-container>
      </tbody>
    </table>
  </ng-template>
</ng-template>
