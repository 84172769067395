// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MITs
export interface Event {
  id: string;
  name: string;
  description: string;
  imageUrl: string;
  from: Date;
  to: Date;
  city: string;
  state: string;
  venue: string;
}

export interface EventSpeaker {
  speakerId: string;
  speakerFirstName: string;
  speakerLastName: string;
  speakerTitle: string;
  submissionDate: string;
  sessionTitle: string;
  accountName: string;
  speakerStatus: 'In Review' | 'Accepted' | 'Rejected';
}
