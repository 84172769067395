<!---- Copyright The Linux Foundation and each contributor to LFX.-->
<!---- SPDX-License-Identifier: MITs -->

<!-- stats -->
<section>
  <lfx-lf-box class="overview-container" [version]="2" [isLoading]="isLoading">
    <div style="display: flex; justify-content: space-between">
      <div *ngIf="stats$ | async as stats" class="overview-stats-box">
        <div *ngFor="let stat of stats" class="lf-stats-container">
          <div class="lf-stats-value">
            {{ stat.value | number }}
            <span class="lf-stats-description">
              {{ stat.text }}
            </span>
          </div>
        </div>
      </div>
      <div><ng-content select="[right]"></ng-content></div>
    </div>
  </lfx-lf-box>
</section>
