// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MITs
export interface TrainingAndCertificationSummary {
  totalEmployees: number;
  trainingSummary: TrainingSummary;
  certificationSummary: CertificationSummary;
}
export interface TnCOverview {
  trainingCourses: number;
  individualsEnrolledInTraining: number;
  certificationTypes: number;
  individualsPassedCertification: number;
}
export interface TnCList {
  courseName: string;
  enrollmentsCount: number;
  productType: string;
  courseGroupId: string;
  courseLink: string;
}

export interface TnCListDetails {
  organizationLogo: string;
  userFullName: string;
  enrollmentTs: string;
  userPhotoUrl: string;
  accountName: string;
  userId: string;
}

export interface TnCListDetailsCount {
  count: number;
}
export interface TnCTrainedEmployeesLeaderboard {
  userId: string;
  userPhotoUrl: string;
  userFullName: string;
  coursesEnrrolledCount: number;
  accountName: string;
}
export interface TnCTrainedEmployeesLeaderboardCount {
  count: number;
}
export interface TnCCertifiedEmployeesLeaderboard {
  userId: string;
  userPhotoUrl: string;
  userFullName: string;
  certificationsCount: number;
  accountName: string;
}
export interface TnCCertifiedEmployeesLeaderboardCount {
  count: number;
}
export interface TnCGrowth {
  year: number;
  month: number;
  spanDate: string;
  instructionType: string;
  coursesEnrrolledCount: number;
}
export interface TnCCorporateVsIndividualEnrollment {
  year: number;
  month?: number;
  quarter?: number;
  spanDate: string;
  instructionType: string;
  enrollmentType: string;
  coursesEnrrolledCount: number;
}
export interface CertificationSummary {
  peopleCertified: number;
  topCertifications: Certification[];
  chartLabels: string[];
  chartData: number[];
  chartMonths: string[];
}
export interface TrainingSummary {
  peopleTrained: number;
  topTrainingPrograms: TrainingProgram[];
  chartLabels: string[];
  chartData: number[];
  chartMonths: string[];
}
export interface Certification {
  name: string;
  id: string;
  certificatesAchieved: number;
}
export interface TrainingProgram {
  name: string;
  id: string;
  enrollments: number;
}
export interface TncCompanyInsights {
  noOfEmployeesEnrolled: number;
  noOfUniqueTraining: number;
  noOfEmployeesCertified: number;
  noOfUniqueCertification: number;
}

export interface TrainedEmployeesByTypeOFTraining {
  instructorLead: number;
  eLearning: number;
}

export interface TrainedEmployeesByGeography {
  country: string;
  value: number;
}

export interface CertifiedEmployeesByAreaOfInterest {
  area: string;
  certified: number;
}

export interface CertifiedEmployeesByGeography {
  country: string;
  value: number;
}
