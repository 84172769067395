// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MITs
import { Component, Input, OnInit } from '@angular/core';
import { Observable, of } from 'rxjs';

@Component({
  selector: 'lfx-overview-tab',
  templateUrl: './overview-tab.component.html',
  styleUrls: ['./overview-tab.component.scss'],
})
export class OverviewTabComponent implements OnInit {
  @Input() isLoading = false;
  @Input() stats$: Observable<{ text: string; value?: number }[] | Error> = of([
    {
      text: 'Events Attended',
      value: 35,
    },
    {
      text: 'Attendees',
      value: 246,
    },
    {
      text: 'Events Spoken At',
      value: 1,
    },
    {
      text: 'Total Speakers',
      value: 1,
    },
    {
      text: 'Registrations',
      value: 2321,
    },
  ]);

  constructor() {}

  ngOnInit(): void {}
}
